import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Flex,
  Stack,
} from "@chakra-ui/react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons"

import {
  Container,
  Layout,
  Section,
  Main,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"
import { VideoPlayer } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

import {
  ProgramsListing,
  SecondaryPagesHero,
  Seo,
  SidebarLinks,
} from "../../../components/elements"

const Inside = ({ data, pageContext }) => {
  const page = data.datoCmsCancerInsideInstitute

  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <Seo
        title={page.seo.title || page.title}
        description={page.seo.description || " "}
        image={page.seo.image ? page.seo.image.url : " "}
      />

      <SecondaryPagesHero page={page}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel={page.title}
        />
      </SecondaryPagesHero>

      {pageContext.slug === "cancer-prevention-and-control" ? (
        <Container width="full">
          <Stack
            direction={["column", "column", "row"]}
            spacing={12}
            py={[9, 9, 12, 16]}
          >
            <Main width={["full", "full", "2 / 3"]}>
              {/* Content */}
              <Flex flexWrap="wrap">
                <Box width="full">
                  <Box
                    variant="styles.twoCols"
                    sx={{ width: ["full"] }}
                    dangerouslySetInnerHTML={{
                      __html: page.contentNode.childMarkdownRemark.html,
                    }}
                  />
                </Box>
              </Flex>
            </Main>

            <Box
              as="aside"
              id="sidebar"
              bg="lightgray"
              width={["full", "full", 1 / 3]}
            >
              <Box p={[3, 3, 6]}>
                <Box as="h3">A Closer Look</Box>

                <ProgramsListing />

                <SidebarLinks page={page} />
              </Box>
            </Box>
          </Stack>
        </Container>
      ) : (
        <Main>
          {/* Content */}
          <Section id="bio">
            <Container>
              <Flex flexWrap="wrap" justifyContent="center">
                <Box width={["full", "full", 2 / 3]}>
                  <Box mb={[9, 9, 12]}>
                    {page.video ? <VideoPlayer url={page.video.url} /> : null}
                  </Box>

                  <Box
                    variant="styles.twoCols"
                    sx={{ width: ["full"] }}
                    dangerouslySetInnerHTML={{
                      __html: page.contentNode.childMarkdownRemark.html,
                    }}
                  />

                  {page?.accordion?.length > 0 ? (
                    <>
                      <Accordion width="full" allowMultiple>
                        {page.accordion.map(({ ...item }) => (
                          <AccordionItem key={item.id}>
                            <AccordionButton>
                              <Box as="h3" flex="1" textAlign="left" mb={0}>
                                {item.title}
                              </Box>
                              <FontAwesomeIcon icon={faChevronDown} />
                            </AccordionButton>

                            <AccordionPanel pt={6}>
                              <Box
                                sx={{ width: ["full"] }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item.descriptionNode.childMarkdownRemark
                                      .html,
                                }}
                              />
                            </AccordionPanel>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    </>
                  ) : null}
                </Box>
              </Flex>
            </Container>
          </Section>
        </Main>
      )}
    </Layout>
  )
}

export default Inside

export const query = graphql`
  query insidePageQuery($slug: String!) {
    datoCmsCancerInsideInstitute(slug: { eq: $slug }) {
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      accordion {
        id
        title
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      id
      title
      slug
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { ar: "1600:720", fit: "crop", crop: "focalpoint" }
        )
      }
      linksNode {
        childMarkdownRemark {
          html
        }
      }
      meta {
        status
      }
      video {
        url
      }
      seo {
        description
        title
        image {
          url
        }
      }
    }
  }
`
